<template>
  <div class="pt-0 pb-4 px-4">
    <div
      v-if="title && item.length > 0"
      class="text-dark-65 font-size-h2 display4-md font-weight-bolder pb-3"
    >
      {{ title }}
    </div>
    <div class="row">
      <div
        v-for="(serverData, i) in item"
        :key="i"
        class="col-12 col-sm-6 col-md-4 col-lg-3 px-1"
      >
        <statistic-item
          :serverData="serverData"
          :color1="getRandomColors(i)"
          :color2="getRandomColors2(i)"
        ></statistic-item>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticItem from "@/own/components/customerService/callAssignment/StatisticItem.vue";
export default {
  name: "Statistics",
  props: {
    item: { required: true },
    title: { required: false, default: null },
  },
  components: {
    StatisticItem,
  },
  data: () => ({
    bgColors: [
      "bg-info text-info",
      "bg-primary text-primary",
      "bg-danger text-danger",
      "bg-warning text-warning",
      "bg-success text-success",
    ],
    bgColors2: [
      "bg-light-info text-info",
      "bg-light-primary text-primary",
      "bg-light-danger text-danger",
      "bg-light-warning text-warning",
      "bg-light-success text-success",
    ],
  }),
  methods: {
    getRandomColors: function (i) {
      // return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
      return this.bgColors[i % 5];
    },
    getRandomColors2: function (i) {
      // return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
      return this.bgColors2[i % 5];
    },
  },
};
</script>
