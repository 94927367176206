<template>
  <v-card
    elevation="0"
    hover
    class="d-block ma-0 pa-2 border-circular poppins"
    :class="color1"
  >
    <div>
      <v-card-text
        class="h6 text-white pb-0"
        style="font-size: 24px !important"
      >
        {{ serverData.name }}
      </v-card-text>
    </div>
    <div>
      <div class="d-flex justify-center align-center mb-1">
        <v-card-text class="ma-0 px-0 pl-4">
          <!-- <div class="h4 pl-1">{{ serverData.dateLabel }}</div> -->
          <div class="text-white ma-0 pr-1 font-size-lg">
            <span class="pr-1">Assigned:</span
            ><span>{{ serverData.value.assign }}</span>
          </div>
          <div class="text-white ma-0 pr-1 font-size-lg">
            <span class="pr-1">Attempted:</span
            ><span>{{ serverData.value.attempts }}</span>
          </div>
          <div class="text-white ma-0 pr-1 font-size-lg">
            <span class="pr-1">Pending:</span
            ><span>{{ serverData.value.pending }}</span>
          </div>
        </v-card-text>
        <v-spacer></v-spacer>
        <div class="pr-4">
          <div>
            <img
              v-if="imageState"
              class="rounded-circle"
              :src="serverData.image"
              width="70px"
              height="70px"
              :alt="serverData.name"
              @error="imageLoadError"
            />
            <div
              v-else
              class="name-letter rounded-circle text-capitalize"
              :class="color2"
            >
              {{ serverData.name[0] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "StatisticItem",
  props: {
    serverData: {
      required: true,
      type: Object,
    },
    color1: {
      required: true,
      type: String,
    },
    color2: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    isImageExist: true,
  }),
  computed: {
    imageState() {
      return this.isImageExist;
    },
  },
  methods: {
    imageLoadError() {
      this.isImageExist = false;
    },
  },
};
</script>
<style scoped>
.border-circular {
  border-radius: 12px;
  /* background-image: linear-gradient(to right, red , yellow); */
}

.name-letter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 76px;
  color: white;
  font-size: 44px;
  text-align: center;
  font-weight: bold;
}
</style>
