<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Assign Call</h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!--        <v-btn rounded color="primary" @click="dialog = false">-->
        <!--          Create-->
        <!--        </v-btn>-->
      </div>
      <v-card-text class="pt-6">
        <!--        entities form-->

        <form id="createForm" class="pa-3 pb-0">
          <v-autocomplete
            v-model="formData.agent_id"
            label="Agent"
            item-text="text"
            item-value="index"
            :items="agents"
            clearable
            required
            :error-messages="agent_idErrors"
            @input="$v.formData.agent_id.$touch()"
            @blur="$v.formData.agent_id.$touch()"
          ></v-autocomplete>
          <v-main class="text-center mt-4">
            <v-btn class="mr-4" color="primary" @click="submitCreateForm">
              Assign
            </v-btn>
            <v-btn @click="resetCreateForm"> Clear </v-btn>
          </v-main>
        </form>
      </v-card-text>

      <div style="flex: 1 1 auto"></div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      agent_id: { required },
    },
  },
  name: "OrderCall",
  props: ["pageLoader", "agents", "updateTableContent"],
  data: () => ({
    dialog: false,
    dateFieldVisible: false,
    formData: {
      agent_id: null,
    },
    item: null,
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setData(itemData) {
      this.item = itemData;
    },
    toggleModal() {
      if (!this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    validateData() {
      return true;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          let data = { order_ids: this.item, ...this.formData };
          ApiService.post(
            "/customer_service/call_assignments/assign_call",
            data
          )
            .then(() => {
              Swal.fire({
                title: "Created",
                text: `call Assigned!`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.toggleModal();
              this.resetCreateForm();
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return null;
        }
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        agent_id: null,
      };
    },
  },
  computed: {
    agent_idErrors() {
      return this.handleFormValidation("agent_id", this);
    },
  },
};
</script>
